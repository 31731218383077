import axios from 'axios';
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export const AddUser = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [conpassword, setConPassword] = useState('');
    const submitForm = ()=>{
        if(email == ''){
          toast.warning('Please input email')
        }else if(password == ''){
          toast.warning('Please input password')
        }else if(conpassword == ''){
          toast.warning('Please input confirm password')
        }else if(conpassword !== password){
          toast.warning('Please check password and confirm password')
        }else{
            const token = localStorage.getItem('token')
            axios.post(`${process.env.REACT_APP_BACK_API}/addNewUser/${token}`, {email, password}).
            then((res)=>{
                toast.success(res.data.message)
            }).
            catch((err)=>{
                toast.success(err.data.message)
            });
        }
      }
    
  return (
    <>
      <Container className='py-5'>
        <Row className="mainDiv">
            <Col md={6} style={{background:'#2f2d53'}}>
            <div className="form-title">
                  New User 
            </div>
            <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="4"> User Email </Form.Label>
                        <Col sm="8">
                            <Form.Control type="email" placeholder=""  className="input-color" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                        </Col>
                    </Form.Group>
              

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="4"> Password </Form.Label>
                        <Col sm="8">
                            <Form.Control type="password" placeholder=""  className="input-color" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="4"> Confirm Password </Form.Label>
                        <Col sm="8">
                            <Form.Control type="password" placeholder=""  className="input-color" value={conpassword} onChange={(e)=>setConPassword(e.target.value)}/>
                        </Col>
                    </Form.Group>
                    <Button variant="outline-warning" className="my-3" onClick={submitForm} style={{float:'right'}}>Submit</Button>
               </Form>
            </Col>
           
        </Row>
        <ToastContainer />
      </Container>
      
    </>
  )
}
