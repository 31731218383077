import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Location from '../../components/Location/Location'


export const ContactUs = () => {
  useEffect(()=>{
    const scroll = ()=>{
      window.scroll({
          top: 600,
          left: 0, 
          behavior: 'smooth',
        });
    }
    scroll();
  
  })
  return (
    <div>
        <Container className='my-3'>
            <Row>
                <Col md={12}>
                        <Location />
                    <div className='py-3'>
                      পাঁচপির বাজার, উলিপুর, কুড়িগ্রাম, ইমেইল:panchpircollege0@gmail.com, মোবাইল: + 88 01714-762967
                    </div>
                </Col>

            </Row>

        </Container>
    </div>
  )
}
