import React from 'react'

const Location = () => {
  return (
    <div>
        <h4 className='text-center m-5'>আমাদের ঠিকানা গুগল ম্যাপে দেখুন</h4>
        <div id="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1511.068896694168!2d89.62856954491096!3d25.73924088189906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e2cbf15851c47f%3A0x4629908103cb27c8!2z4Kaq4Ka-4KaB4Kaa4Kaq4KeA4KawIOCmoeCmv-Cml-CnjeCmsOCngCDgppXgprLgp4fgppw!5e0!3m2!1sen!2sbd!4v1723993855577!5m2!1sen!2sbd" width="100%" height="450" frameborder="0" style={{border:'0'}} allowfullscreen id="ifrem_id"></iframe>
        </div>
    </div>
  )
}

export default Location
