import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
const Footer = () => {
  return (
    <footer className="footer pt-5">
      <Container>
        <Row>
          <Col md={4} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Panchpir Degree College</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
            <p>
              <div>
                <a href="https://panchpircollege.com/">
                  <img src="./photos/logo.png" alt="logo" className="logo" />
                </a>
              </div>
            </p>
          </Col>

        
          <Col md={4} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Useful links</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
           
            <p>
              <a href="https://www.techedu.gov.bd/">
                Directorate Of Technichal Education
              </a>
            </p>

            <p>
              <a href="https://www.bteb.gov.bd/">
                Bangladesh Technical Education Board (BTEB)
              </a>
            </p>

            <p>
              <a href="https://www.step-dte.gov.bd/">
                Skills And Training Enhancement Project
              </a>
            </p>

            <p>
              <a href="https://www.bpsc.gov.bd/">
                Bangladesh Public Service Commission (BPSC)
              </a>
            </p>

            <p>
              <a href="https://www.bcc.gov.bd/">
                Bangladesh Computer Council (BCC)
              </a>
            </p>
          </Col>

          <Col md={4} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Contact</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
            <p>Panchpir Bazar Road,<br/>
              Ulipur, Kurigram</p>
            <p>
               panchpircollege0@gmail.com
            </p>
            <p>
             + 88 01714-762967
            </p>
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        &copy; 2024 Panchpir Degree College. All Rights Reserved. Design, Development and Maintenance by ICT Cell
      </div>
    </footer>
  );
};

export default Footer;
