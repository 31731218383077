import React, { useState } from "react";
import "./Notice.css";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Notice = () => {
  const navigate = useNavigate();
  const [noticeTitle, setNoticeTitle] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState('')
  const [viewfile, setViewFile] = useState('')
 
  const formData = new FormData()
  formData.append('file', file)
  formData.append('noticeTitle', noticeTitle)
  formData.append('publishDate', publishDate)
  formData.append('category', category)


  const submitForm = ()=>{
    if(noticeTitle == ''){
      toast.warning('Please input notice title')
    }else if(publishDate == ''){
      toast.warning('Please input upload date')
    }else if(category == ''){
      toast.warning('Please select category')
    }else if(file == ''){
      toast.warning('Please select notice file')
    }else{
      axios.post(`${process.env.REACT_APP_BACK_API}/uploadNoticeData`, formData).
      then((res)=>{
          if(res.data.result === 'success'){
            toast.success('Data successfully inserted')
            setNoticeTitle('')
            setPublishDate('')
            setCategory('')
            setFile('')
            setViewFile('')
          }
      }).
      catch((err)=>{
          console.log(err)
      });
    }
  }

  const imageChangHandaler = (e) => {
    const fileUpload = e.target.files[0]
    if (fileUpload) {
      setFile(e.target.files[0])
      setViewFile(URL.createObjectURL(fileUpload))
    }
  }

  const myStyle = {
    show: {
      display: 'block',
      border: '3px solid white',
    },
    hide: {
      display: 'none',
    },
  }
  return (
    <>
      <Container>
        <Row className="mainDiv">
          <Col md={6} className="mt-2 bg-blueLight" >
                <div className="form-title">
                  New Notice 
                </div>
                <Form enctype="multipart/form-data">
                
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3"> Notice Title </Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="" value={noticeTitle} className="input-color" onChange={(e)=>setNoticeTitle(e.target.value)}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3"> Publish Date </Form.Label>
                    <Col sm="9">
                      <Form.Control type="date" placeholder="" value={publishDate} className="input-color" onChange={e=>setPublishDate(e.target.value)}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3"> Select Category </Form.Label>
                    <Col sm="9">
                      <select className="form-control" value={category} onChange={(e)=>{setCategory(e.target.value)}}>
                        <option value="">Select One</option>
                        <option value="genarel">Genarel</option>
                        <option value="hsc">H.S.C</option>
                        <option value="degree">Degree</option>
                        <option value="honours">Honours</option>
                      </select>
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Select PDF File</Form.Label>
                      <Form.Control type="file" onChange={imageChangHandaler} key={file}/>
                     
                      <img
                      className="upload_file"
                      src={viewfile}
                      style={viewfile ? myStyle.show : myStyle.hide}
                    />
                  </Form.Group>
                  
                  <Button variant="outline-warning" className="my-3" onClick={submitForm}>Upload Notice</Button>
                </Form>
              
          </Col>
        </Row>
        <ToastContainer />
      </Container>
      
    </>
  );
};

export default Notice;
