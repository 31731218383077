import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'


export const Notice = () => {
    const location = useLocation();
    const [noticeSec, setNoticeSec] = useState('');
    const [alNotice, setAllNotice] = useState();
    const [noticeCategory, setNoticeCategory] = useState();
    
    useEffect(()=>{
        const scroll = ()=>{
            window.scroll({ top: 700, left: 0,  behavior: 'smooth'});
        }
        scroll()

        if(location.state === null){
            console.log("empty data");
        }else{
            const {section} = location.state;
            setNoticeSec(section);
            axios.get(`${process.env.REACT_APP_BACK_API}/getNotice/${noticeSec}`)
            .then((result)=>{
                setAllNotice(result.data.allNotice)
                
            }).catch((err)=>{
                console.log(err)
            })
        }
       
        console.log(alNotice)
    })

    const getDate = (str)=>{
        const data = str.split("-")
        return data[2]+"-"+data[1]+"-"+data[0];
    }
    const getDownloadLink = (data)=>{
            const link = `${process.env.REACT_APP_BACK_API}/public/${data}`
            return link;
    }
    
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
  return (
    <div>
        <Container className='mt-3'>
            <Row>
                <Col md={12}>
                    <h4>Notice Category:  {capitalizeFirstLetter(noticeSec)}</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Notice Title</th>
                                <th>Published Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                        {alNotice && alNotice.map((items, index)=>{
                             
                            return(
                               
                                <tr key={index}>
                                <td width="50px">{index+1}</td>
                                <td>{items.notice_title}</td>
                                <td width="150px">{<FontAwesomeIcon icon={faCalendarDays} />} {getDate(items.published_date)}</td>
                                <td width="20px"><a href={getDownloadLink(items.file_name)} target='_blank'><FontAwesomeIcon icon={faDownload} style={{fontSize:'20px'}}/></a></td>
                                </tr>
                            )
                            
                         })
                        }
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>

    </div>
  )
}
