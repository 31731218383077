import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const Video = () => {
  return (
    <div className='my-5'>
        <Container>
            <Row>

                <Col md={4}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/skzsOauA0d8?si=PhuuMJkCuJiMYlfC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Col>

                <Col md={4}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/lcLrPNqkuDA?si=WzuPQnm-fOMr3Ef9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Col>

                <Col md={4}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/N8-c_4yF4Sc?si=nXAkjI1zNJ1vtRAg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Col>

            </Row>
        </Container>

    </div>
  )
}
