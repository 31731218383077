import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Gallery.css'

const scroll = ()=>{
  window.scroll({
      top: 700,
      left: 0, 
      behavior: 'smooth',
    });
}

export const Gallery = (props) => {
  const activity = props.result.activity;
    useEffect(()=>{
        
        if(activity === 'true'){
            scroll();
        }
        
    });
  return (
    <Container className='mt-5'>
        <Row>
            <Col md={3} className='photo'>
                <img src="./photos/logo.png" alt="" className='img-fluid' />
            </Col>
            
        </Row>
    </Container>
  )
}
