import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import './Staff.css';
const Staff = () => {
    const [teachersData, setTeachersData] = useState();
useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACK_API}/teachersData`).then((res)=>{
        setTeachersData(res.data);
    }).catch((err)=>{
        
    })
})

  return (
    <>
        <Container className='my-4'>
            <div className='staffHeader py-2'>
                অত্র প্রতিষ্ঠানের শিক্ষক মন্ডলীর তালিকা
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.priority <= 2 ){
                    return(
                        
                            <Col md={6} className='my-3' key={index}> 
                                <div className='staffData '>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid1' />
                                    <div className='staffBodyData py-2'>
                                        <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
               

               {teachersData && teachersData.map((item, index)=>{
                    if(item.priority >= 3 && item.priority <= 4){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>




            <div className='staffHeader py-2'>
                    কলেজের শিক্ষকবৃন্দ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'college_techear'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>




            <div className='staffHeader py-2'>
                    কলেজের কর্মচারীবৃন্দ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'college_staff'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>



            <div className='staffHeader py-2'>
                    সম্মান শ্রেণির বিভাগীয় প্রধানগণ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'honour_head'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>




            <div className='staffHeader py-2'>
                   বাংলা (সম্মান) বিভাগীয় প্রধানগণ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'bangla_bivag'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>


            <div className='staffHeader py-2'>
                   রাষ্ট্রবিজ্ঞান (সম্মান) বিভাগীয় প্রধানগণ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'rastro_bivag'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>


            <div className='staffHeader py-2'>
                   ব্যবস্থাপনা (সম্মান) বিভাগীয় প্রধানগণ
            </div>
            <Row>
                {teachersData && teachersData.map((item, index)=>{
                    if(item.section == 'management_bivag'){
                    return(
                        
                            <Col md={4} className='my-3' key={index}> 
                                <div className='staffData'>
                                    <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo}`} alt="" className='tech_photo img-fluid' />
                                    <div className='staffBodyData py-2'>
                                    <div className='pt-2'>
                                            {item.techarsName}
                                        </div>
                                        <div className=''>
                                            {item.designation}
                                        </div>
                                        <div className=''>
                                            {item.othersText}
                                        </div>
                                        <div className=''>
                                            {item.othersText2}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                    )
                    }
                })}
            </Row>
        </Container>
    </>
  )
}

export default Staff