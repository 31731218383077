import React, { useEffect, useState } from 'react'
import './Notice.css'
import axios from 'axios'

export const Notice = () => {
    const [noticeData, setNoticeData] = useState();
    const token = localStorage.getItem('token')
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACK_API}/all_notice`).then((res)=>{
            setNoticeData(res.data);
        }).catch((err)=>{

        });
    })

    const getDate = (str)=>{
        const data = str.split("-")
        return data[2]+"-"+data[1]+"-"+data[0];
    }
    const getDownloadLink = (data)=>{
            const link = `${process.env.REACT_APP_BACK_API}/public/${data}`
            return link;
    }
  return (
    <>
    <div className='notice'>
            <ul>
                {noticeData && noticeData.map((item, index)=>{
                    return(
                            <li key={index}>
                                <div className='noticeDiv'>
                                    <div className='noticeTitle'>
                                        {item.notice_title}
                                    </div>
                                    <div className='noticeFooter'>
                                            <div className='noticeDate'>
                                                Published: {getDate(item.published_date)}
                                            </div>
                                            <div className='noticePreview'>
                                                <a className='mybtn' href={getDownloadLink(item.file_name)} target='_blank'> + Read More </a>
                                            </div>
                                    </div>
                                </div>
                            </li>
                        )
                    

                })}
              

                
               
            </ul>
    </div>
    </>
    
  )
}
