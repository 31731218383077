import React from 'react'
import './LoadingPage.css'
import PuffLoader from "react-spinners/PuffLoader";

export const LoadingPage = () => {
  return (
    <div className='loadClass'>

        <PuffLoader
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  )
}
