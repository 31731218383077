import React, { useEffect, useState } from 'react'
import './Principal.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
export const Principal = () => {
    const [administratorData, setAdministratorData] = useState('');

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACK_API}/administratorData`).then((res)=>{
            setAdministratorData(res.data);
        }).catch((err)=>{
            
        });
    });
  return (
    <div className='my-3'>
        <Container>
          
                
                {administratorData && administratorData.map((item, index)=>{
                    if(item.priority === 1){
                        return (
                            <Row>
                                    <Col md={3}>
                                        <div className='photoSection my-1' key={index}>
                                            <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo_name}`} alt="Principal Photo" />
                                                <i>{item.u_name}</i>
                                            <span style={{fontWeight:'bolder', paddingBottom:'10px'}}>{item.post}</span>
                                        </div>
                                    </Col>
                                    <Col md={9} >
                                        <div className='baniSection my-1'>
                                        <span style={{fontWeight:'bold', color:'rgb(16 16 72)'}}>সভাপতি মহোদয়ের বাণী</span>
                                        <p className='my-2 principal_speach'>
                                            {item.lecture}       
                                        </p>
                                        <Link to="/administrator" state={{uid: item.uid}} className='mybtn'>+ Read More</Link>
                                      
                                        </div>
                                        
                                    </Col>
                                </Row>
                            
                            
                        );
                    }
                    
                })}
                   
                

                
           
            <Row className='mt-3'>
                {administratorData && administratorData.map((item, index)=>{
                     if(item.priority != 1){
                         return(
                            <Col md={3} key={index}>
                                <Link to="/administrator" state={{uid: item.uid}}>
                                    <div className='photoSection my-1'>
                                        <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photo_name}`} alt="Photo" className='img-fluid'/>
                                            <b style={{color:'black'}}>{item.u_name}</b>
                                        <span style={{fontWeight:'bolder', paddingBottom:'10px', color:'navyblue'}}>{item.post}</span>
                                    </div>
                                </Link>
                            </Col>
                        )
                    }
                })
                }
               

            </Row>
        </Container>

    </div>
  )
}
