import React, { useEffect, useState } from 'react'
import './Slider.css'
import { Col, Container, Row } from 'react-bootstrap'
import {Notice} from './Notice'

import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';



export const Slider = () => {
    const [sliderData, setSliderData] = useState();
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACK_API}/getSliderData`).then((res)=>{
            setSliderData(res.data);
        }).catch((err)=>{
    
        });
    });
  return (
    <div>
        <Container className='mt-5'>
            <Row>
                <Col md={8}>
                    <div className='protHeader mt-3'>
                        News & Events
                    </div>
                    <div className='carousel' style={{height:'450px'}}>
                        <Carousel>
                            {sliderData && sliderData.map((item, index)=>{
                                return(
                                    <Carousel.Item interval={4000}>
                                        <img src={`${process.env.REACT_APP_BACK_API}/public/${item.photos_name}`} className='carouselImg' alt='image' />
                                        <Carousel.Caption>
                                            <p className='carouselCaption'>{item.caption}</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>

                                );
                            })}
                           
                           
                        </Carousel>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='protHeader mt-3'>
                        Recent Notice
                    </div>
                    <Notice />
                </Col>
            </Row>
        </Container>

    </div>
  )
}
