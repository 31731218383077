import React, { useEffect } from "react";
import "./Motive.css";
import { Col, Container, Row } from "react-bootstrap";
import Aos from "aos";
import SubPrograms from '../SubPrograms/SubPrograms'
const Motive = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="mt-3">
      <Container>
        <Row className="motiveRow">
          <Col className="graduationImg" sm={12} md={4}>
            <img src="./gra.png" alt="graduation" className="img-fluid" />
          </Col>

          <Col className="col-md-8 justify-center">
            <span className="ourTech">আমাদের ব্যাচ সমুহঃ</span>
            <Row>
              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front civil-img">
                          <div className="img-cap">
                            <span className="text-white">
                              এইচ.এস.সি
                            </span>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>H.S.C</h3>
                          <p>
                            If You Interested
                            <span href="" className="btn btn-success">
                              Read More
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>

              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front et-img">
                          <div className="img-cap">
                            <span className="text-white">
                              
                              ডিগ্রি
                            </span>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>DEGREE</h3>
                          <p>
                            If You Interested
                            <span className="btn btn-success">
                              Read More
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front cmt-img">
                          <div className="img-cap">
                            <span className="text-white">
                              অনার্স
                            </span>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>HONOURS</h3>
                          <p>
                            If You Interested
                            <span className="btn btn-success">
                              Read More
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <SubPrograms />

       

      
      </Container>
    </div>
  );
};

export default Motive;
