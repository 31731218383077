import React, { useState } from "react";
import "./Notice.css";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const NewsAndEvents = () => {
    const navigate = useNavigate();
    const [catiopn, setCatiopn] = useState('');
   
    const [file, setFile] = useState('')
    const [viewfile, setViewFile] = useState('')
   
    const formData = new FormData()
    formData.append('file', file)
    formData.append('catiopn', catiopn)
  
    const submitForm = ()=>{
      if(catiopn == ''){
        toast.warning('Please input caption')
      }else if(file == ''){
        toast.warning('Please select notice file')
      }else{
        axios.post(`${process.env.REACT_APP_BACK_API}/uploadNewsEvents`, formData).
        then((res)=>{
            if(res.data.result === 'success'){
              toast.success('Data successfully inserted')
              setCatiopn('')
              setFile('')
              setViewFile('')
            }
        }).
        catch((err)=>{
            console.log(err)
        });
      }
    }
  
    const imageChangHandaler = (e) => {
      const fileUpload = e.target.files[0]
      if (fileUpload) {
        setFile(e.target.files[0])
        setViewFile(URL.createObjectURL(fileUpload))
      }
    }
  
    const myStyle = {
      show: {
        display: 'block',
        border: '3px solid white',
      },
      hide: {
        display: 'none',
      },
    }
    return (
      <>
        <Container>
          <Row className="mainDiv">
            <Col md={6} className="mt-2 bg-blueLight" >
                  <div className="form-title">
                   News & Events file upload
                  </div>
                  <Form enctype="multipart/form-data">
                  
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                      <Form.Label column sm="3"> Caption </Form.Label>
                      <Col sm="9">
                        <Form.Control type="text" placeholder="" value={catiopn} className="input-color" onChange={(e)=>setCatiopn(e.target.value)}/>
                      </Col>
                    </Form.Group>
  
                  
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Select Photo</Form.Label>
                        <Form.Control type="file" onChange={imageChangHandaler} key={file}/>
                       
                        <img
                        className="upload_file"
                        src={viewfile}
                        style={viewfile ? myStyle.show : myStyle.hide}
                      />
                    </Form.Group>
                    
                    <Button variant="outline-warning" className="my-3" onClick={submitForm}>Upload Notice</Button>
                  </Form>
                
            </Col>
          </Row>
          <ToastContainer />
        </Container>
        
      </>
    );
}

export default NewsAndEvents