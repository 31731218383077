import React, { useState } from "react";
import "./Notice.css";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const TeachersProfileUpload = () => {

    const navigate = useNavigate();
    const [administratorPriority, setAdministratorPriority] = useState('');
    const [administratorName, setAdministratorName] = useState('');
    const [designation, setDesignation] = useState('');
    const [comments, setComments] = useState('');
    const [comments2, setComments2] = useState('');
  
    const [file, setFile] = useState('')
    const [viewfile, setViewFile] = useState('')
   
    const formData = new FormData()
   
    formData.append('administratorPriority', administratorPriority)
    formData.append('administratorName', administratorName)
    formData.append('designation', designation)
    formData.append('comments', comments)
    formData.append('comments2', comments2)
    formData.append('file', file)
  
    const submitForm = ()=>{
      if(administratorName == ''){
        toast.warning('Please input administrator name ')
      }else if(administratorPriority == ''){
        toast.warning('Please input administrator priority number')
      }else if(designation == ''){
        toast.warning('Please input designation')
      }else if(comments == ''){
        toast.warning('Please input comments')
      }
      else if(file == ''){
        toast.warning('Please select notice file')
      }else{
        
        axios.post(`${process.env.REACT_APP_BACK_API}/inputTechersData`, formData).
        then((res)=>{
            console.log(res);
            if(res.data.result === 'success'){
              toast.success('Data successfully inserted')
              setAdministratorName('')
             
                setDesignation('')
                setComments('')
                setComments2('')
                setFile('')
                setViewFile('')
                setAdministratorPriority('')
            }
        }).
        catch((err)=>{
            console.log(err)
        });
      }
    }
  
    const imageChangHandaler = (e) => {
      const fileUpload = e.target.files[0]
      if (fileUpload) {
        setFile(e.target.files[0])
        setViewFile(URL.createObjectURL(fileUpload))
      }
    }
  
    const myStyle = {
      show: {
        display: 'block',
        border: '3px solid white',
      },
      hide: {
        display: 'none',
      },
    }


  return (
    <Container>
        <Row className="mainDiv">
          <Col md={6} className="mt-2 bg-blueLight" >
                <div className="form-title">
                  Teachers File Upload
                </div>
                <Form enctype="multipart/form-data">
                
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3">Priority</Form.Label>
                    <Col sm="9">
                      <Form.Control type="number" placeholder="" value={administratorPriority} className="input-color" onChange={(e)=>setAdministratorPriority(e.target.value)}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3">Name</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="" value={administratorName} className="input-color" onChange={(e)=>setAdministratorName(e.target.value)}/>
                    </Col>
                  </Form.Group>

                
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3">Designation</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="" value={designation} className="input-color" onChange={(e)=>setDesignation(e.target.value)}/>
                    </Col>
                  </Form.Group>
                 
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3"> Others Text 1</Form.Label>
                    <Col sm="9">
                    <Form.Control
                        as="textarea"
                        placeholder="Others Text"
                        style={{ height: '50px' }}
                        value={comments}
                        onChange={(e)=>{setComments(e.target.value)}}
                        />
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label column sm="3"> Others Text 2</Form.Label>
                    <Col sm="9">
                    <Form.Control
                        as="textarea"
                        placeholder="Others Text"
                        style={{ height: '50px' }}
                        value={comments2}
                        onChange={(e)=>{setComments2(e.target.value)}}
                        />
                    </Col>
                  </Form.Group>

                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Select Photo</Form.Label>
                      <Form.Control type="file" onChange={imageChangHandaler} key={file}/>
                     
                      <img
                      className="upload_file"
                      src={viewfile}
                      style={viewfile ? myStyle.show : myStyle.hide}
                    />
                  </Form.Group>
                  
                  <Button variant="outline-warning" className="my-3" onClick={submitForm}>Upload Data</Button>
                </Form>
              
          </Col>
        </Row>
        <ToastContainer />
      </Container>




  )
}

export default TeachersProfileUpload