import React from 'react';
import ReactDOM from 'react-dom/client';



import './index.css';

import Header from './components/Header/Header';

import reportWebVitals from './reportWebVitals';
import Motive from './components/Motive/Motive';
import CountUpp from './components/CountUp/CountUpp';
import Location from './components/Location/Location';
import Footer from './components/Footer/Footer';
import Admission from './components/Admission/Admission';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import LogIn from './Elements/Login/LogIn';
import Dashboard from './Dashboard/Dashboard';
import ShortCourse from './ShortCourse/ShortCourse';
import { Notice } from './Elements/Notice/Notice';
import { Gallery } from './Elements/Gallery/Gallery';
import { ContactUs } from './Elements/ContactUs/ContactUs';
import { Slider } from './components/SliderAndNotice/Slider';
import { Principal } from './components/Principal/Principal';
import { Video } from './components/Video/Video';
import Bani from './components/Principal/Bani';
import Staff from './components/Staff/Staff';


const route = createBrowserRouter([
  {
    path: "/",
    element: <>
    <Header result={{activity: 'true'}}/>
    <Slider />
    <Principal />
    <Motive />
    <CountUpp />

    <Admission result={{activity: 'false'}}/>
    <Video />
    <Location />
    <Footer />
    </>
  }, 
  {
    path: "/notice",
    element: <>
      <Header result={{activity: 'false'}}/>
      <Notice />
      <Footer />
    </>
  },
  {
    path: "/admission",
    element: <>
      <Header result={{activity: 'false'}}/>
      <Admission result={{activity: 'true'}}/>
      <Footer />
    </>
  },
  {
    path: "/administrator",
    element: <>
      <Header result={{activity: 'false'}}/>
        <Bani />
      <Footer />
    </>
  },
  {
    path: "/staff",
    element: <>
      <Header result={{activity: 'false'}}/>
        <Staff />
      <Footer />
    </>
  },
  {
    path: "/gallery",
    element: <>
      <Header result={{activity: 'false'}}/>
      <Gallery result={{activity: 'true'}}/>
      <Footer />
    </>
  },
  {
    path: "/contact",
    element: <>
      <Header result={{activity: 'false'}}/>
      <ContactUs />
      <Footer />
    </>
  },
  {
    path: "/log",
    element : <LogIn />
  },
  {
    path: "/dashboard",
    element : <Dashboard page={{name: ''}}/>
  },
  {
    path: "/dashboard/notice",
    element : <>
      <Dashboard page={{name: 'Notice'}}/>
    </>
  },
  {
    path: "/dashboard/newsandevent",
    element : <>
      <Dashboard page={{name: 'NewsAndEvents'}}/>
    </>
  },
  {
    path: "/dashboard/addUser",
    element : <>
      <Dashboard page={{name: 'addUser'}}/>
    </>
  },
  {
    path: "/dashboard/administration",
    element : <>
      <Dashboard page={{name: 'Authorisetion_data_send'}}/>
    </>
  },
  {
    path: "/dashboard/teachers",
    element : <>
      <Dashboard page={{name: 'teachers'}}/>
    </>
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <RouterProvider router={route} />
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
