import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Principal.css'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
const Bani = () => {
    const navigation = useNavigate();
    const [baniName, setBaniName] = useState();
    const [baniPost, setBaniPost] = useState();
    const [baniPhoto, setBaniPhoto] = useState();
    const [baniData, setBaniData] = useState();
    const location = useLocation()
    const uid = location.state.uid
   
    const scroll = ()=>{
        window.scroll({
            top: 400,
            left: 0, 
            behavior: 'smooth',
          });
    }

    useEffect(()=>{
        scroll()
        axios.get(`${process.env.REACT_APP_BACK_API}/administratorDataWithId/${uid}`).then((res)=>{
            setBaniName(res.data.u_name);
            setBaniPost(res.data.post);
            setBaniPhoto(res.data.photo_name);
            setBaniData(res.data.lecture);
        }).catch((err)=>{
            
        });
        
    },[])

  return (
    <>
    <Container>
        <Row className='bani my-3'>
        <Col md={12} className='baniHead'>
            <h1>বাণী </h1>
        </Col>
        <Col md={12} className='imagesSection'>
                <div className='bani_img my-3'>
                    <img src={`${process.env.REACT_APP_BACK_API}/public/${baniPhoto}`} alt="Principal Photo" className='img-fluid baniPhoto'/>
                </div>   
               
                <div className='designation'>
                    <h3 className='py-1'>{baniName}</h3>
                    <h4 className='py-1'>{baniPost}</h4>
                </div>
            </Col>
            <Col md={12} className='mt-2'>
                <div className='baniText'>
                    {baniData}
                </div>   
            </Col>
            
        </Row>

    </Container>
    </>
    
  )
}

export default Bani